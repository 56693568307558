import { useCallback, useTransition } from 'react';
import {
  useRecoilState,
  useRecoilState_TRANSITION_SUPPORT_UNSTABLE,
  useSetRecoilState,
} from 'recoil';

import { LeadFormType } from '@/app/_components/modals/enquire-forms/enquiry-forms/enquiry-forms';
import {
  drawerState,
  floatingCTAShowState,
  formConfigState,
  formItemState,
  NewCarItem,
  pageFormItemState,
  primaryButtonActionState,
  secondaryFloatingButtonState,
  SpecialItem,
  UsedCarItem,
} from '@/states/floating-cta.state';

type FormNames = {
  [key in LeadFormType]: string;
};

export const FORM_NAMES = {
  'new-cars': 'NewCars',
  'used-cars': 'UsedCars',
  newsletter: 'Newsletter',
  parts: 'Parts',
  service: 'Service',
  general: 'TestDrive',
  'test-drive': 'TestDrive',
  'trade-in': 'TradeIn',
} as FormNames;

export const useOpenFloatingCTA = () => {
  const setFormConfig = useSetRecoilState(formConfigState);
  const setFormItem = useSetRecoilState(formItemState);
  const setIsDrawerOpen = useSetRecoilState(drawerState);

  const [_isPending, startTransition] = useTransition();

  return useCallback(
    (
      leadType: LeadFormType,
      formName?: string,
      item?: NewCarItem | UsedCarItem | SpecialItem,
    ) => {
      setFormConfig({
        formName: formName
          ? formName
          : FORM_NAMES[item?.type === 'special' ? item.leadType : leadType],
        leadType: item?.type === 'special' ? item.leadType : leadType,
      });
      if (item) {
        setFormItem(item);
      }
      startTransition(() => {
        setIsDrawerOpen(true);
      });
    },
    [setFormConfig, setFormItem, setIsDrawerOpen],
  );
};

export const useSetFormItem = () => {
  const [_pageFormItem, setPageFormItem] = useRecoilState(pageFormItemState);

  return setPageFormItem;
};

export const useFloatingCTA = () => {
  const [isDrawerOpen, setIsDrawerOpen] =
    useRecoilState_TRANSITION_SUPPORT_UNSTABLE(drawerState);
  const [isCTAShown, setIsCTAShown] =
    useRecoilState_TRANSITION_SUPPORT_UNSTABLE(floatingCTAShowState);
  const [formConfig, setFormConfig] =
    useRecoilState_TRANSITION_SUPPORT_UNSTABLE(formConfigState);
  const [formItem, setFormItem] =
    useRecoilState_TRANSITION_SUPPORT_UNSTABLE(formItemState);
  const [pageFormItem, setPageFormItem] =
    useRecoilState_TRANSITION_SUPPORT_UNSTABLE(pageFormItemState);
  const [secondaryFloatingButton, setSecondaryFloatingButton] =
    useRecoilState_TRANSITION_SUPPORT_UNSTABLE(secondaryFloatingButtonState);
  const [primaryButtonAction, setPrimaryButtonAction] =
    useRecoilState_TRANSITION_SUPPORT_UNSTABLE(primaryButtonActionState);

  const [_isPending, startTransition] = useTransition();

  const openLeadForm = useCallback(
    (
      leadType: LeadFormType,
      formName?: string,
      item?: NewCarItem | UsedCarItem | SpecialItem,
    ) => {
      setFormConfig({
        formName: formName
          ? formName
          : FORM_NAMES[item?.type === 'special' ? item.leadType : leadType],
        leadType: item?.type === 'special' ? item.leadType : leadType,
      });
      if (item) {
        setFormItem(item);
      }
      startTransition(() => {
        setIsDrawerOpen(true);
      });
    },
    [],
  );

  const closeLeadForm = () => {
    startTransition(() => {
      setIsDrawerOpen(false);
    });
  };

  const clearLeadForm = () => {
    setFormItem(undefined);
  };

  return {
    isDrawerOpen,
    leadFormName: formConfig.formName,
    openLeadForm,
    closeLeadForm,
    clearLeadForm,
    leadType: formConfig.leadType,
    formConfig,
    formItem: formItem || pageFormItem,
    setFormItem: setPageFormItem,
    isCTAShown,
    setIsCTAShown,
    secondaryFloatingButton,
    setSecondaryFloatingButton,
    primaryButtonAction,
    setPrimaryButtonAction,
  };
};
