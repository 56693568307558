import {
  Box,
  Flex,
  Heading,
  Link,
  List,
  ListItem,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
} from '@chakra-ui/react';
import { useRef } from 'react';

import { ReactComponent as ChevropDown } from '@/app/_icons/CkChevronDown.svg';
import { ReactComponent as CkClose } from '@/app/_icons/CkClose_black.svg';
import { ReactComponent as KiaLogo } from '@/app/_icons/kia-logo.svg';
import {
  finance,
  financialServiceLeftMenu,
  serviceAndMaintenancePlans,
  valueAddedProducts,
  warrantyOptions,
} from '@/constants/financial-service/financial-service.constants';

import styles from './MegaMenu.module.scss';

function FinancialServices({ isMobile = false }) {
  const initRef = useRef<HTMLDivElement>(null);

  return (
    <Popover
      trigger="hover"
      placement="bottom"
      returnFocusOnClose={true}
      variant={!isMobile ? 'fullWidth' : 'mobileWidth'}
      initialFocusRef={initRef}
    >
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Link
              role="link"
              href="javascript:void(0)"
              className={styles.megaMenuLink}
            >
              Financial Services
              <ChevropDown className={styles.menuIcon} />
            </Link>
          </PopoverTrigger>

          <PopoverContent
            bg="white"
            className={styles.megaMenuWrapper}
            width="100%"
            border="0"
            borderRadius="0"
            top={11}
            bottom="0"
            left="0"
            right="0"
            boxShadow="none"
            zIndex="auto"
          >
            <PopoverBody p={0}>
              {!isMobile ? (
                <Box className={styles.megamenuHeader}>
                  <Box className={styles.megamenuFilter}>
                    <Box marginLeft={'auto'}>
                      <PopoverCloseButton
                        role="button"
                        className={styles.megamenuFilterClose}
                      >
                        <CkClose className={styles.megamenuFilterClose} />
                      </PopoverCloseButton>
                    </Box>
                  </Box>
                </Box>
              ) : null}
              <Box className={styles.megamenuBodyWrap}>
                <Box className={styles.megamenuBody}>
                  <SimpleGrid
                    columns={[1, 2, 3, 4, 5]}
                    spacingX="40px"
                    spacingY="40px"
                  >
                    <Box className={styles.megaMenuList}>
                      <Box className={styles.sectionTitle}>
                        <Flex
                          flexDirection="row"
                          alignItems={'self-start'}
                          mb={6}
                        >
                          <KiaLogo width={59} height={14} />
                          <Heading
                            as="h6"
                            className={styles.titleXs}
                            marginLeft={2}
                          >
                            Financial Services
                          </Heading>
                        </Flex>
                      </Box>
                      <List spacing={3}>
                        {financialServiceLeftMenu?.map((item, index) => (
                          <ListItem key={index}>
                            <Link role="link" target="_blank" href={item.path}>
                              {item.name}
                            </Link>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                    <Box className={styles.megaMenuList}>
                      <Box className={styles.sectionTitle}>
                        <Heading as="h6" className={styles.titleXs} mb={6}>
                          Service & Maintenance Plans
                        </Heading>
                      </Box>
                      <List spacing={3}>
                        {serviceAndMaintenancePlans?.map((item, index) => (
                          <ListItem key={index}>
                            <Link role="link" target="_blank" href={item.link}>
                              {item.name}
                            </Link>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                    <Box className={styles.megaMenuList}>
                      <Box className={styles.sectionTitle}>
                        <Heading as="h6" className={styles.titleXs} mb={6}>
                          Finance
                        </Heading>
                      </Box>
                      <List spacing={3}>
                        {finance?.map((item, index) => (
                          <ListItem key={index}>
                            <Link role="link" target="_blank" href={item.link}>
                              {item.name}
                            </Link>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                    <Box className={styles.megaMenuList}>
                      <Box className={styles.sectionTitle}>
                        <Heading as="h6" className={styles.titleXs} mb={6}>
                          Warranty Options
                        </Heading>
                      </Box>
                      <List spacing={3}>
                        {warrantyOptions?.map((item, index) => (
                          <ListItem key={index}>
                            <Link role="link" target="_blank" href={item.link}>
                              {item.name}
                            </Link>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                    <Box className={styles.megaMenuList}>
                      <Box className={styles.sectionTitle}>
                        <Heading as="h6" className={styles.titleXs} mb={6}>
                          Value Added Products
                        </Heading>
                      </Box>
                      <List spacing={3}>
                        {valueAddedProducts?.map((item, index) => (
                          <ListItem key={index}>
                            <Link role="link" href={item.link}>
                              {item.name}
                            </Link>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </SimpleGrid>
                </Box>
              </Box>
              <Box onClick={onClose} ref={initRef} className={styles.overlay} />
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
}

export default FinancialServices;
