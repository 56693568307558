import isNull from 'lodash/isNull';

import { CarsDealership } from '@/types/dealership/dealership.types';
import { DealershipReq } from '@/types/request/request.types';

export function mapDealershipResponse(
  dealership: DealershipReq,
): CarsDealership {
  return {
    id: dealership.id,
    main_image: dealership.main_image,
    name: dealership.name,
    main_number: dealership.main_number,
    city: dealership.city?.name,
    location: [
      dealership.address_1,
      dealership.address_2,
      dealership.city?.name,
      dealership.code,
    ]
      .filter((item) => item)
      .join(', '),
    google_address: dealership.google_address,
    enquiry_form_services: dealership?.enquiry_form_services,
    operating_hours: dealership.operating_hours
      .map((item) => {
        if (!item.operating_hours_id) {
          return {
            id: null,
            day_of_the_week: '',
            hours: '',
          };
        }

        const { id, day_of_the_week, closing_time, opening_time } =
          item.operating_hours_id as any;

        return {
          id: id % 7,
          day_of_the_week: day_of_the_week.slice(0, 3),
          hours: closing_time
            ? opening_time + ' - ' + closing_time
            : opening_time,
        };
      })
      .filter((item) => !isNull(item.id))
      .sort((a, b) => {
        const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

        return (
          daysOfWeek.indexOf(a.day_of_the_week) -
          daysOfWeek.indexOf(b.day_of_the_week)
        );
      }),
  };
}
