'use client';

import {
  Box,
  Heading,
  Link,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { parseCookies, setCookie } from 'nookies';
import { useEffect, useRef, useState } from 'react';
import slugify from 'react-slugify';

import { PriceFormat } from '@/app/_components/price-format/price-format';
import { ReactComponent as ArroRightBlack } from '@/app/_icons/arrow-right-black.svg';
import { ReactComponent as ChevropDown } from '@/app/_icons/CkChevronDown.svg';
import { ReactComponent as CkClose } from '@/app/_icons/CkClose_black.svg';
import { getImageLink } from '@/services/link.service';
import { ImageTransformationKeys } from '@/types/files/files.types';
import { NewCar } from '@/types/new-cars/new-cars.types';

import CustomImage from '../../custom-image/CustomImage';

import styles from './MegaMenu.module.scss';

interface Props {
  isMobile?: boolean;
  newCars: NewCar[];
  selectedDealershipName?: string;
}

const imageKeys: ImageTransformationKeys = {
  desktopKey: 'new-car-megamenu-desktop',
  mobileKey: 'new-car-megamenu-mobile',
};

function Vehicles({
  isMobile = false,
  newCars,
  selectedDealershipName,
}: Props) {
  const [alreadySeenVehicles, setAlreadySeenVehicles] = useState<number[]>([]);

  const initRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const cookies = parseCookies();

    if (cookies?.whereYouLeft) {
      const parsedJson = JSON.parse(cookies['whereYouLeft']);

      if (!parsedJson || !Array.isArray(parsedJson)) return;
      if (parsedJson.length > 4) parsedJson.length = 4;
      setAlreadySeenVehicles(parsedJson);
    }
  }, []);

  const redirectSetCookies = async (vehicleID: number) => {
    const isAlreadySeen = alreadySeenVehicles.find((id) => id === vehicleID);

    if (isAlreadySeen) {
      let index = alreadySeenVehicles.findIndex((id) => id === vehicleID);

      const seen = alreadySeenVehicles.splice(index, 1)[0];

      if (seen) {
        alreadySeenVehicles.unshift(seen);
      }

      setCookie(
        null,
        'whereYouLeft',
        JSON.stringify([...alreadySeenVehicles]),
        {
          maxAge: 86400,
        },
      );

      return true;
    }
    alreadySeenVehicles.length > 4
      ? (alreadySeenVehicles.shift(), alreadySeenVehicles.unshift(vehicleID))
      : alreadySeenVehicles.unshift(vehicleID);

    setCookie(null, 'whereYouLeft', JSON.stringify([...alreadySeenVehicles]), {
      maxAge: 86400,
    });
    return true;
  };

  return (
    <Popover
      trigger="hover"
      placement="bottom"
      returnFocusOnClose={true}
      variant={!isMobile ? 'fullWidth' : 'mobileWidth'}
      initialFocusRef={initRef}
    >
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Link
              role="link"
              href="javascript:void(0)"
              className={styles.megaMenuLink}
            >
              New Cars
              <ChevropDown className={styles.menuIcon} />
            </Link>
          </PopoverTrigger>

          <PopoverContent
            bg="white"
            className={styles.megaMenuWrapper}
            width="100%"
            border="0"
            borderRadius="0"
            top={11}
            bottom="0"
            left="0"
            right="0"
            boxShadow="none"
            zIndex="auto"
          >
            <PopoverBody p={0}>
              <Box className={styles.megamenuHeader}>
                <Box className={styles.megamenuFilter}>
                  <Link
                    role="link"
                    className={`${styles.badge} ${styles.active}`}
                    href="/new-cars"
                  >
                    New Vehicles
                  </Link>
                  <Link
                    role="link"
                    className={styles.badge}
                    href="/used-cars/search"
                  >
                    Certified Pre-owned
                  </Link>
                  <Link
                    role="link"
                    className={styles.badge}
                    href="/used-cars/demo/search"
                  >
                    Demo Vehicles
                  </Link>

                  <Link
                    role="link"
                    className={styles.badge}
                    href="/build-price"
                  >
                    Build Your Kia
                  </Link>
                  {!isMobile ? (
                    <Box marginLeft={'auto'}>
                      <PopoverCloseButton
                        role="button"
                        className={styles.megamenuFilterClose}
                      >
                        <CkClose />
                      </PopoverCloseButton>
                    </Box>
                  ) : null}
                </Box>
              </Box>
              <Box
                className={`${styles.megamenuBodyWrap} ${
                  alreadySeenVehicles && Boolean(alreadySeenVehicles.length)
                    ? styles.alreadySeenVehicles
                    : ''
                }`}
              >
                <Box className={styles.megamenuBodyTop}>
                  <Box
                    className={`${styles.megamenuBody} ${styles.newVehicle}`}
                  >
                    {alreadySeenVehicles &&
                      Boolean(alreadySeenVehicles.length) && (
                        <>
                          <Box className={styles.sectionTitle} marginBottom={8}>
                            <Heading
                              as="h2"
                              className={styles.titleLg}
                              marginBottom={4}
                            >
                              Pick up where you left off
                            </Heading>
                            <Text>Keep shopping for that perfect ride.</Text>
                          </Box>
                          <SimpleGrid
                            columns={[2, 2, 3, 4]}
                            className={styles.vehicleList}
                            spacingX={['10px', '30px', '50px', '100px']}
                            spacingY={['50px', '50px', '50px', '100px']}
                          >
                            {alreadySeenVehicles &&
                              alreadySeenVehicles.map((vehicleID: number) => {
                                const vehicle = newCars.find(
                                  (item) => item.id === vehicleID,
                                );

                                if (!vehicle) return null;

                                return (
                                  <Box
                                    className={styles.vehicleCard}
                                    key={vehicle.vehicle_preview?.id}
                                  >
                                    <Link
                                      role="link"
                                      className={styles.carLink}
                                      href={`/new-cars/${slugify(vehicle.main_title)}`}
                                      onClick={() =>
                                        redirectSetCookies(vehicle?.id)
                                      }
                                    >
                                      <CustomImage
                                        imageKeys={imageKeys}
                                        imageSrc={
                                          getImageLink(
                                            vehicle.image_no_background_left_angle,
                                          ) ?? ''
                                        }
                                        className={styles.carImage}
                                        alt={`${vehicle.vehicle_preview?.title}`}
                                        width={240}
                                        height={140}
                                      />
                                    </Link>
                                    <Box className={styles.carDetails}>
                                      <Link
                                        role="link"
                                        className={styles.modelName}
                                        href={`/new-cars/${slugify(vehicle.main_title)}`}
                                        onClick={() =>
                                          redirectSetCookies(vehicle.id)
                                        }
                                      >
                                        {vehicle?.main_title}
                                      </Link>
                                      <Text className={styles.carPrice}>
                                        From{' '}
                                        <PriceFormat
                                          prefix="R"
                                          value={vehicle.price_from || 'N/A'}
                                        />
                                      </Text>
                                      <Box className={styles.carCTA}>
                                        {vehicle?.hide_in_build_and_price ? null : (
                                          <Link
                                            role="link"
                                            className={styles.themeLink}
                                            href={`/build-price/${slugify(vehicle.main_title)}`}
                                            onClick={() =>
                                              redirectSetCookies(vehicle.id)
                                            }
                                          >
                                            Build
                                            <ArroRightBlack
                                              className={styles.menuIcon}
                                            />
                                          </Link>
                                        )}
                                        <Link
                                          role="link"
                                          className={styles.themeLink}
                                          href={
                                            selectedDealershipName
                                              ? `/dealerships/${slugify(selectedDealershipName)}`
                                              : '/find-a-dealer'
                                          }
                                        >
                                          Find Nearby
                                          <ArroRightBlack
                                            className={styles.menuIcon}
                                          />
                                        </Link>
                                      </Box>
                                    </Box>
                                  </Box>
                                );
                              })}
                          </SimpleGrid>
                        </>
                      )}
                  </Box>
                </Box>

                <Box className={`${styles.megamenuBody} ${styles.newVehicle}`}>
                  <Box className={`${styles.sectionTitle}`}>
                    <Heading
                      as="h2"
                      className={`${styles.titleLg}`}
                      marginBottom={8}
                    >
                      Vehicles
                    </Heading>
                  </Box>
                  <SimpleGrid
                    columns={[2, 2, 3, 4]}
                    className="vehicleList"
                    spacingX={['10px', '30px', '50px', '100px']}
                    spacingY={['50px', '50px', '50px', '100px']}
                  >
                    {newCars &&
                      newCars.map((vehicle: NewCar) => {
                        return (
                          <Box
                            className={styles.vehicleCard}
                            key={vehicle.vehicle_preview?.id}
                          >
                            <Link
                              role="link"
                              className={styles.carLink}
                              href={`/new-cars/${slugify(vehicle.main_title)}`}
                              onClick={() => redirectSetCookies(vehicle.id)}
                            >
                              <CustomImage
                                imageKeys={imageKeys}
                                imageSrc={
                                  getImageLink(
                                    vehicle.image_no_background_left_angle,
                                  ) ?? ''
                                }
                                className={styles.carImage}
                                alt={`${vehicle.vehicle_preview?.title}`}
                                width={240}
                                height={140}
                              />
                            </Link>
                            <Box className={styles.carDetails}>
                              <Link
                                role="link"
                                href={`/new-cars/${slugify(vehicle.main_title)}`}
                                className={styles.modelName}
                                onClick={() => redirectSetCookies(vehicle.id)}
                              >
                                {vehicle.main_title}
                              </Link>
                              <Text className={styles.carPrice}>
                                From{' '}
                                <PriceFormat
                                  prefix="R"
                                  value={vehicle.price_from || 'N/A'}
                                />
                              </Text>
                              <Box className={styles.carCTA}>
                                {vehicle?.hide_in_build_and_price ? null : (
                                  <Link
                                    role="link"
                                    className={styles.themeLink}
                                    href={`/build-price/${slugify(vehicle?.main_title)}`}
                                    onClick={() =>
                                      redirectSetCookies(vehicle?.id)
                                    }
                                  >
                                    Build
                                    <ArroRightBlack
                                      className={styles.menuIcon}
                                    />
                                  </Link>
                                )}
                                <Link
                                  role="link"
                                  href={
                                    selectedDealershipName
                                      ? `/dealerships/${slugify(selectedDealershipName)}`
                                      : '/find-a-dealer'
                                  }
                                  className={styles.themeLink}
                                >
                                  Find Nearby
                                  <ArroRightBlack className={styles.menuIcon} />
                                </Link>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                  </SimpleGrid>
                </Box>
              </Box>
              <Box onClick={onClose} ref={initRef} className={styles.overlay} />
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
}

export default Vehicles;
