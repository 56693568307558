'use client';

import React from 'react';
import { RecoilRoot } from 'recoil';

export default function Component({
  children,
  initialState,
}: {
  children: React.ReactNode;
  initialState?: Record<string, any>;
}) {
  return (
    <RecoilRoot initializeState={() => initialState}>{children}</RecoilRoot>
  );
}
